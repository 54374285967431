import {
  PATH_ENTRY,
  PATH_OPPORTUNITIES,
  PATH_OTHER,
  PATH_PORTFOLIO,
  PATH_PRE_ENTRY,
} from './paths';
import { paths } from './util';

// Other paths are secured within components
// or via wizard guards
// Authentication should really be handled here though...
export const SecureRoutes = [...paths(PATH_PORTFOLIO), PATH_OTHER.documents, PATH_OTHER.tokens];

// SSO paths must be secured to display
// the login page (sso error page if not logged in)
export const SecureSSORoutes = [
  ...paths(PATH_OPPORTUNITIES),
  ...paths(PATH_PRE_ENTRY),
  ...paths(PATH_ENTRY),
  ...paths(PATH_PORTFOLIO),
  ...paths(PATH_OTHER, {
    omit: [PATH_OTHER.notFound],
  }),
];
