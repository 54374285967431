import React, { PropsWithChildren } from 'react';
import { CNavItem, CNavLink } from '@coreui/react';
export interface ITabItem {
  dataTab: string;
  onClick: (tab: string) => void;
}

const TabItem = ({ children, dataTab, onClick, ...props }: PropsWithChildren<ITabItem>) => {
  const toggle = (tab: string) => {
    if (window.history.pushState) {
      const path = location.href.split('?')[0];
      window.history.pushState(null, '', `${path}?${new URLSearchParams({ tab }).toString()}`);
      onClick(tab);
    }
  };

  return (
    <CNavItem onClick={() => toggle(dataTab)} {...props}>
      <CNavLink data-tab={dataTab}>{children}</CNavLink>
    </CNavItem>
  );
};

export default TabItem;
