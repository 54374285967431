import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { translate } from '@utils/translate';
import { errorToast } from '@components';
import { HttpCommonErrorTypes } from '@utils/error-types';
import { InvestableAmountDto } from '@web-api-client';
import { IHttpErrorResponse } from '../types';
import {
  createEntryNext,
  createInvestmentNext,
  createPreEntryNext,
  getInvestableAmountNext,
} from './actionTypes';

type IInvestmentErrorResponse = IHttpErrorResponse<HttpCommonErrorTypes | null>;
export interface IInvestmentState {
  readonly isLoading: boolean;
  readonly isError: boolean;
  readonly error: IInvestmentErrorResponse | null;

  readonly investableAmount: InvestableAmountDto | null;
  readonly isInvestableAmountLoading: boolean;
}

export const initialInvestmentState: IInvestmentState = {
  isLoading: true,
  isError: false,
  error: null,

  investableAmount: null,
  isInvestableAmountLoading: false,
};

export const investment = createReducer(initialInvestmentState, builder =>
  builder
    .addCase<string, PayloadAction<void>>(createPreEntryNext.INIT, state => {
      state.isLoading = true;
      state.isError = false;
    })
    .addCase<string, PayloadAction<IInvestmentErrorResponse>>(
      createPreEntryNext.ERROR,
      (state, { payload }) => {
        if (payload.name === HttpCommonErrorTypes.INVESTMENT_ALREADY_EXISTS) {
          window.setTimeout(() => errorToast(translate('Errors.investment-already-exists')));
        }
        return {
          ...state,
          isLoading: false,
          isError: true,
          error: payload,
        };
      },
    )

    .addCase<string, PayloadAction<void>>(createPreEntryNext.SUCCESS, state => {
      state.isLoading = false;
    })
    .addCase<string, PayloadAction<void>>(createEntryNext.INIT, state => {
      state.isLoading = true;
      state.isError = false;
    })
    .addCase<string, PayloadAction<IInvestmentErrorResponse>>(
      createEntryNext.ERROR,
      (state, { payload }) => {
        if (payload.name === HttpCommonErrorTypes.INVESTMENT_ALREADY_EXISTS) {
          window.setTimeout(() => errorToast(translate('Errors.investment-already-exists')));
        }

        return {
          ...state,
          isLoading: false,
          isError: true,
          error: payload,
        };
      },
    )
    .addCase<string, PayloadAction<void>>(createEntryNext.SUCCESS, state => {
      state.isLoading = false;
    })
    .addCase<string, PayloadAction<void>>(createInvestmentNext.INIT, state => {
      state.isLoading = true;
      state.isError = false;
    })
    .addCase<string, PayloadAction<IInvestmentErrorResponse>>(
      createInvestmentNext.ERROR,
      (state, { payload }) => {
        if (payload.name === HttpCommonErrorTypes.INVESTMENT_ALREADY_EXISTS) {
          window.setTimeout(() => errorToast(translate('Errors.investment-already-exists')));
        } else {
          window.setTimeout(() => errorToast(translate('Errors.server-error')));
        }

        state.isLoading = false;
        state.isError = true;
        state.error = payload;
      },
    )
    .addCase<string, PayloadAction<void>>(createInvestmentNext.SUCCESS, state => {
      state.isLoading = false;
    })
    .addCase<string, PayloadAction<void>>(getInvestableAmountNext.INIT, state => {
      state.investableAmount = null;
      state.isInvestableAmountLoading = true;
    })
    .addCase<string, PayloadAction<InvestableAmountDto>>(
      getInvestableAmountNext.SUCCESS,
      (state, { payload }) => {
        state.investableAmount = payload;
        state.isInvestableAmountLoading = false;
      },
    )
    .addCase<string, PayloadAction<void>>(getInvestableAmountNext.ERROR, state => {
      state.investableAmount = null;
      state.isInvestableAmountLoading = false;
    }),
);
